import React, { useEffect } from 'react';

// material-ui
import { Table, TableRow, TableBody, TableCell, TableContainer, Tooltip, TablePagination } from '@mui/material';
import TableHeader from 'ui-component/TableHead';
import { useTheme } from '@mui/material/styles';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { getAllLoggedInMerchants } from 'utils/api';
import { RefreshOutlined } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import { useDispatch } from 'react-redux';
import axiosServices from 'utils/axios';
import ConfirmationModal from 'ui-component/Modal';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';

// table header options
const headCells = [
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'name',
        numeric: false,
        label: 'Username',
        align: 'left'
    },
    {
        id: 'mfa',
        numeric: false,
        label: 'MFA',
        align: 'left'
    },
    {
        id: 'merchantStatus',
        numeric: false,
        label: 'Status',
        align: 'left'
    }
];

const LoggedInMerchants = () => {
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(500);
    const [selectedUserMFA, setSelectedUserMFA] = React.useState(false);

    const fetchDataFromAPI = async (page, rowsPerPage) => {
        setLoading(true);
        try {
            const result = await getAllLoggedInMerchants(page, rowsPerPage);
            setLoading(false);
            setData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI(page, rowsPerPage);
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleMFA = () => {
        setLoading(true);
        axiosServices
            .put(`updateIsMfaEnabled`, {
                userId: selectedUserMFA?.id,
                mfaEnabled: !selectedUserMFA?.isMfaEnabled
            })
            .then((res) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Success',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                fetchDataFromAPI(page, rowsPerPage);
                dispatch(closeSnackbar);
                setSelectedUserMFA(false);
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Logged In Merchants</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}

            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: 500, width: 300 }}
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                    >
                                        <div>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                            <div>Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.username}</TableCell>
                                    <TableCell
                                        onClick={() => {
                                            setSelectedUserMFA(row);
                                        }}
                                        style={{ fontWeight: 'bold', color: '#38b6fd', cursor: 'pointer' }}
                                    >
                                        {row.isMfaEnabled ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell>{row.isUserDisabled ? 'Disabled' : 'Active'}</TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmationModal
                loading={loading}
                open={selectedUserMFA}
                title={`${selectedUserMFA?.mfaEnabled ? 'Disable MFA' : 'Enable MFA'}`}
                subTitle="Are you sure, you want to continue"
                handleClose={() => setSelectedUserMFA(false)}
                handleSubmit={handleMFA}
            />

            {/* <ConfirmationModal
                open={selectedBank}
                title="Delete LoggedInMerchants"
                subTitle="Are you sure, you want to delete selected LoggedInMerchants"
                handleClose={() => setSelectedBank(false)}
                handleSubmit={deleteProxy}
            /> */}

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[200]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default LoggedInMerchants;
